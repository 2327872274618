import { Component, Input } from '@angular/core';
// PrimeNG
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-loading-page',
  standalone: true,
  imports: [ProgressSpinnerModule],
  templateUrl: './loading-page.component.html',
  styleUrl: './loading-page.component.scss'
})
export class LoadingPageComponent {
  @Input() height = 'h-content';
}
